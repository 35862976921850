import request from '@/libs/requestinvitation'

const invitation = {
    auth(params) {
        return request({//获取用户唯一标示
            url: '/food/invite/getId',
            method: 'get',
            params
        })
    },
    joinlist(params) {//参加宴会的用户列表
        return request({
            url: '/food/invite/join/list',
            method: 'get',
            params
        })
    },
    timelist(params) {//查询宴请时间
        return request({
            url: '/food/invite/time/list',
            method: 'get',
            params
        })
    },
    joincheck(params) {//判断用户是否登记宴席
        return request({
            url: '/food/invite/join/check',
            method: 'get',
            params
        })
    },
    invitesave(data) {//保存宾客信息
        return request({
            url: '/food/invite/save',
            method: 'post',
            data
        })
    },
    inviteupdate(data) {//修改参加时间
        return request({
          url: '/food/invite/update',
          method: 'post',
          data
        })
      },
}
export default invitation