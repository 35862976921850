<template>
  <!-- :style="{height:windowHeight+'px'}" -->
  <div style="color: #000;height: 100%;overflow-y: scroll;" class="hed">
    <audio id="audio" :src="require('../../assets/imgs/mps.mp3')"></audio>
    <img class="heds" src="../../assets/imgs/heds.png" alt />
    <div class="mian">
      <img class="heds" src="../../assets/imgs/jsbeijin.png" alt />
      <div class="neirong">
        <div class="h1">一份新梦想 一份邀请函</div>
        <div class="h2">
          现在好，我很高兴也很荣幸，能以这样的方式向您发出问好，我叫蓝天，来自湖南娄底新化，一个环境非常美丽，国家自然与文化双遗产，世界梯田之最的名胜景区－紫鹊界。
          最近几年，我看到身边很多的朋友由于工作的原因，出现某种程度上的身心健康，身体健康受损，以及我们现在每天吃的喝的，都让我深深的感到担忧，于是我有了一个新的梦想，让我所有的股东，我身边所有的朋友们能有一个身心愉悦，健康的体魄，能呼吸纯静的空气，能吃上健康的饭菜，能心灵健康的生活，在未来10年，我们将全力投入康养相关的行业。
          今天，一个偶然的机缘，让我有了一个机会从0到1的突破，在东莞大朗（最美丽的中国松山湖旅游区附近）投资了一个以天然的，健康的食材，并以宽心来命名的山庄，东莞宽心雅苑餐饮投资有限公司之湛江菜。
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="dian_Img" src="../../assets/imgs/mgs.jpg" alt />
          </van-swipe-item>
          <van-swipe-item>
            <img class="dian_Img" src="../../assets/imgs/lun1.jpg" alt />
          </van-swipe-item>
          <van-swipe-item>
            <img class="dian_Img" src="../../assets/imgs/lun2.jpg" alt />
          </van-swipe-item>
          <van-swipe-item>
            <img class="dian_Img" src="../../assets/imgs/lun3.jpg" alt />
          </van-swipe-item>
          <van-swipe-item>
            <img class="dian_Img" src="../../assets/imgs/lun4.jpg" alt />
          </van-swipe-item>
        </van-swipe>

        <div
          class="h3"
        >农庄于2021年 月 号 点正式开业，也借此这个机会，邀请您一共来庆祝为个美好而又神圣的日子，我现在正式邀请您来赴宴，我的事业一路走来有您的扶持，现在更不能少了您，不见不散。最后祝您家庭幸福，事业兴隆，合家美满。</div>
        <div class="tito">
          <span>邀请人：</span>
          <img class="lt" src="../../assets/imgs/lt.png" alt />
        </div>
        <div class="tito">电话：13888889999</div>
      </div>
    </div>
    <img class="dizhi" @click="daohang" src="../../assets/imgs/dizhi.png" alt />
    <van-button class="jies" @click="tojieshou" type="primary">
      <span v-if="biaoshi == false">接受邀请</span>
      <span v-else>已参加</span>
    </van-button>
    <van-button class="jied" type="primary" @click="tuichu">下次再来</van-button>
    <div class="botts">
      <div class="bota">
        <img class="xiobiao" style="margin-right: 10px;" src="../../assets/imgs/ys.png" alt />
        <span>接受邀请的朋友</span>
        <img class="xiobiao" style="margin-left: 10px;" src="../../assets/imgs/ys.png" alt />
      </div>
      <div class="botitol" v-for="(item,idx) in list" :key="idx">
        <div class="left">{{item.name}}</div>
        <div class="right">礼金{{item.money?item.money.toFixed(2):''}}元 于 {{item.presenceDate?item.presenceDate.substring(0,10):''}} 莅临</div>
      </div>
    </div>
    <van-popup v-model="show" closeable class="tancuan">
      <div class="tan_a">接受邀请</div>
      <div class="tan_mian">
        <div class="mian_a">
          <span class="left">姓&#12288名&#12288</span>
          <input v-model="name" class="left inputs" type="text" />
        </div>
        <div class="mian_a">
          <span class="left">电&#12288话&#12288</span>
          <input v-model="phone" class="left inputs" type="number" />
        </div>
        <div class="mian_a">
          <span class="left">公司名&#12288</span>
          <input v-model="company" class="left inputs" type="text" />
        </div>
        <div class="mian_a">
          <span class="left">莅临日期</span>
          <div class="left timeput" @click="showtiem = true">{{presenceDate}}</div>
          <img class="riqis" src="../../assets/imgs/riqi.png" alt />
        </div>
        <div class="libao" v-if="biaoshi == false">
          <div class="li_a">选择礼金红包</div>
          <div class="hbdiv">
            <div class="hbdic" @click="redjin(0)">
              <img v-if="index == 0" class="redimg" src="../../assets/imgs/red2.png" alt />
              <img v-else class="redimg" src="../../assets/imgs/red1.png" alt />
              <span class="nube">88.88</span>
            </div>
            <div class="hbdic" @click="redjin(1)">
              <img v-if="index == 1" class="redimg" src="../../assets/imgs/red2.png" alt />
              <img class="redimg" v-else src="../../assets/imgs/red1.png" alt />
              <span class="nube">888.88</span>
            </div>
            <div class="hbdic" @click="redjin(2)">
              <img v-if="index == 2" class="redimg" src="../../assets/imgs/red2.png" alt />
              <img class="redimg" v-else src="../../assets/imgs/red1.png" alt />
              <span class="nube">1888.88</span>
            </div>
          </div>
          <div class="zidingyi" @click="toziding" v-if="showjin == false">自定义红包金额</div>
          <div class="zis" v-else>
            <span class="dingjw">￥</span>
            <input type="number" v-model="money" class="ziinput" />
          </div>
        </div>
        <div class="libao" v-else>
          <div class="li_a">已参与</div>
          <div class="hbdiv">
            <div class="hbdicd">
              <img class="redimg" src="../../assets/imgs/red1.png" alt />
              <span class="nube">{{money}}</span>
            </div>
          </div>
        </div>
        <van-button class="tijiao" v-if="biaoshi == false" type="primary" @click="tijiao">提交</van-button>
        <van-button class="tijiao" v-else type="primary" @click="xiugai">修改</van-button>
      </div>
    </van-popup>
    <van-popup v-model="showtiem" closeable position="bottom" :style="{ height: '34%' }">
      <div class="weilin">选择莅临日期</div>
      <div class="ctimes">
        <div v-for="(item,idx) in formime" :key="idx" class="tisms" @click="totime(item)">
          <span :class="item.remainingInvitees <= 0?'acddv':''">{{item.invitationDate?item.invitationDate.substring(0,10):''}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import invitation from "../../api/invitation";
import userModel from "../../api/user";

// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      openId: localStorage.getItem("userid"),
      show: false,
      showtiem: false,
      biaoshi: false, //是否购买标识
      showjin: false,
      index: null,
      list: [],
      windowHeight: document.documentElement.clientHeight,
      formime: [],
      form: {},
      loading: false,
      company: "", //公司名称
      money: "", //礼金
      name: "", //宾客名称，
      phone: "", //手机号码
      presenceDate: "", //参喜时间
      presenceDateId: "", //时间id
      total: 0,
      finished: false
    };
  },
  methods: {
    //选择时间
    totime(item) {
      if (item.remainingInvitees <= 0) {
        this.$toast("该时间已约满，请选择别的时间！");
        return;
      }
      this.presenceDate = item.invitationDate;
      this.presenceDateId = item.id;
      this.showtiem = false;
    },
    //修改已提交数据
    xiugai() {
      var _this = this;
      if (this.name == "") {
        this.$toast("请先输入您的名称");
        return;
      }
      if (_this.phone == "" || !_this.phone) {
        this.$toast("请先输入手机号码");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(_this.phone)) {
        this.$toast("手机号码格式错误");
        return;
      }
      var data = {
        company: this.company,
        money: this.money,
        name: this.name,
        openId: this.openId,
        phone: this.phone,
        presenceDate: this.presenceDate,
        presenceDateId: this.presenceDateId,
        id: this.form.id
      };
      invitation.inviteupdate(data).then(res => {
        if (res.code == 200) {
          this.onLoads();
          this.namelist();
          this.show = false;
          this.$toast.success("修改成功");
        }
      });
    },
    //提交宴请
    tijiao() {
      var _this = this;
      if (this.name == "") {
        this.$toast("请先输入您的名称");
        return;
      }
      if (_this.phone == "" || !_this.phone) {
        this.$toast("请先输入手机号码");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(_this.phone)) {
        this.$toast("手机号码格式错误");
        return;
      }
      console.log(this.showjin);
      if (this.showjin == true) {
        console.log("走了这");
        if (this.money < 100 || this.money == "") {
          this.$toast("自定义金额请输入大于100元！");
          return;
        }
      } else {
        if (this.index == null) {
          this.$toast("请选择金额红包！");
          return;
        }
      }
      var data = {
        company: this.company,
        money: this.money,
        name: this.name,
        openId: this.openId,
        phone: this.phone,
        presenceDate: this.presenceDate,
        presenceDateId: this.presenceDateId
      };
      invitation.invitesave(data).then(res => {
        if (res.code == 200) {
          if (typeof WeixinJSBridge === "undefined") {
            // 微信浏览器内置对象。参考微信官方文档
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                _this.onBridgeReady(res.data),
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent(
                "WeixinJSBridgeReady",
                _this.onBridgeReady(res.data)
              );
              document.attachEvent(
                "onWeixinJSBridgeReady",
                _this.onBridgeReady(res.data)
              );
            }
          } else {
            console.log("准备调用微信支付");
            _this.onBridgeReady(res.data);
          }
        }
      });
    },
    tuichu() {
      WeixinJSBridge.call("closeWindow");
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.onLoads();
            vm.namelist();
            vm.show = false;
            vm.$toast.success("支付成功");
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    onLoads() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      invitation
        .joinlist()
        .then(e => {
          loading.clear();
          this.list = e.data;

          console.log(this.list);
        })
        .catch(() => loading.clear());
      invitation
        .timelist()
        .then(e => {
          this.formime = e.data;

          console.log(this.formime);
        })
        .catch();
    },
    //点击金额红包
    redjin(e) {
      this.showjin = false;
      if (e == 0) {
        this.money = 88.88;
      } else if (e == 1) {
        this.money = 888.88;
      } else {
        this.money = 1888.88;
      }
      this.index = e;
    },
    //自定义红包
    toziding() {
      this.index = null;
      this.money = "";
      this.showjin = true;
    },
    //接受邀请弹窗
    tojieshou() {
      this.show = true;
    },
    //导航
    daohang() {
      var positionWd = Number(22.942688);
      var positionJd = Number(113.910159);
      wx.openLocation({
        latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
        longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
        name: "宽心雅苑（湛江菜）", // 位置名
        address: "广东省东莞市大朗镇聚新四路252号", // 地址详情说明
        scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "" // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: 208
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["onMenuShareAppMessage", "openLocation"]
            });

            var link =
              "https://shopping.car-posthouse.cn/invitation?denglu=true";

            wx.ready(() => {
              wx.onMenuShareAppMessage({
                title: "宽心雅苑",
                desc: "邀请函",
                link: link,
                imgUrl:
                  "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/999/1624529206703heds.png?Expires=1939889206&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=GWdAF8edxbWRXJtNpADj49sCOAw%3D",
                success: function() {}
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    namelist() {
      invitation
        .joincheck({
          openId: this.openId
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res.data);
            if (res.data == undefined) {
              this.biaoshi = false;
            } else {
              this.biaoshi = true;
              var data = res.data;
              this.name = data.name;
              this.phone = data.phone;
              this.company = data.company;
              this.presenceDate = data.presenceDate;
              this.presenceDateId = data.presenceDateId;
              this.money = data.money;
              this.form = res.data;
            }
          } else {
            this.Toast(res.data.msg);
          }
        });
    },
    audioAutoPlay() {
      let audio = document.getElementById("audio");
      audio.play();
      document.removeEventListener("touchstart", this.audioAutoPlay);
    }
  },
  created() {
    console.log(localStorage.getItem("userid"));
    return
    if (this.$route.query.denglu != undefined) {
      if (
        localStorage.getItem("userid") == null ||
        localStorage.getItem("userid") == undefined ||
        localStorage.getItem("userid") == "null" ||
        localStorage.getItem("userid") == "undefined"
      ) {
        var appid = "wx406b62706ded5aa8";
        // 正式
        const redirect_uri = encodeURIComponent(
          "https://shopping.car-posthouse.cn/authsinvitation"
        );
        // const redirect_uri = encodeURIComponent(
        //   "http://localhost:8080/authsinvitation"
        // );
        window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        return;
      }
    }

    this.onLoads();
    this.namelist();
    this.getConfig();
    var isok = localStorage.getItem("isok");
      document.addEventListener("WeixinJSBridgeReady", function() {
      document.getElementById("audio").play();
    });
    // if (isok == "true") {
    //   setTimeout(res => {
    //     this.$router.go(0);
    //   }, 100);
    //   localStorage.removeItem("isok");
    // }
  },
  mounted() {
  
    // let oAudio = document.querySelector("#audio");
    // oAudio.onended = function() {
    //   //播放完毕，重新循环播放
    //   oAudio.load();
    //   oAudio.play();
    // };
    // this.audioAutoPlay();
  }
};
</script>

<style lang="less" scoped>
.heds {
  width: 100%;
  display: block;
}
.hed {
  background: url(../../assets/imgs/hed.png);
  background-size: 100%;
}
.mian {
  position: relative;
  width: 90%;
  margin: auto;
  margin-top: 35px;
}
.neirong {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.h1 {
  font-family: AlibabaPuHuiTi-Medium;
  font-size: 16px;
  color: #9b251d;
  letter-spacing: 0;
  text-align: center;
  font-weight: 600;
  padding: 15px 0 10px 0;
}
.h2 {
  font-family: AlibabaPuHuiTi-Regular;
  font-size: 13px;
  color: #666666;
  letter-spacing: 0;
  width: 90%;
  margin: auto;
  line-height: 22px;
}
.dian_Img {
  width: 90%;
  display: block;
  margin: auto;
  height: 145px;
  margin-top: 10px;
}
.h3 {
  font-family: AlibabaPuHuiTi-Medium;
  font-size: 13px;
  color: #a52a21;
  letter-spacing: 0;
  width: 90%;
  margin: auto;
  margin-top: 35px;
  line-height: 21px;
  font-weight: 600;
}
.tito {
  font-family: AlibabaPuHuiTi-Medium;
  font-size: 13px;
  font-weight: 600;
  color: #a52a21;
  letter-spacing: 0;
  width: 90%;
  margin: auto;
  text-align: right;
  line-height: 22px;
  overflow: hidden;
}
.lt {
  height: 20px;
  vertical-align: bottom;
}
.dizhi {
  width: 90%;
  margin: auto;
  display: block;
  margin-top: 18px;
}
.jies {
  background: #f3a539;
  border-radius: 4px;
  border: 1px solid #f3a539;
  width: 90%;
  display: block;
  margin: auto;
  font-family: AlibabaPuHuiTi-Medium;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 0;
  margin-top: 20px;
}
.jied {
  background: #a52a21;
  border: 1px solid rgba(243, 165, 57, 0.65);
  border-radius: 4px;
  width: 90%;
  display: block;
  margin: auto;
  font-size: 22px;
  margin-top: 20px;
  color: #f3a539;
}
.botts {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
  min-height: 400px;
}
.bota {
  font-size: 15px;
  padding: 20px 0 12px 0;
  color: #ffffff;
  //   font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
}
.xiobiao {
  vertical-align: middle;
  height: 15px;
}
.botitol {
  overflow: hidden;
  font-family: AlibabaPuHuiTi-Regular;
  font-size: 13px;
  color: #ffbab5;
  letter-spacing: 0;
  padding: 0 12px;
  line-height: 32px;
}
.tisms {
  font-size: 16px;
  line-height: 50px;
  overflow: hidden;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}
.weilin {
  font-size: 18px;
  line-height: 45px;
  overflow: hidden;
  text-align: center;
}
.ctimes {
  padding: 0px 15px;
}
.tancuan {
  width: 90%;
  border-radius: 4px;
  padding-bottom: 20px;
}
.tan_a {
  font-size: 18px;
  color: #070707;
  text-align: center;
  padding: 15px 0;
}
.tan_mian {
  width: 90%;
  margin: auto;
  overflow: hidden;
}
.mian_a {
  font-size: 14px;
  color: #070707;
  line-height: 35px;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
}
.riqis {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  margin-right: 18px;
  margin-top: 5px;
}
.inputs {
  width: 75%;
  border: 1px solid #979797;
  border-radius: 4px;
  line-height: 35px;
  margin-left: 8px;
  padding: 0 10px;
}
.timeput {
  width: 75%;
  border: 1px solid #979797;
  border-radius: 4px;
  line-height: 35px;
  margin-left: 8px;
  padding: 0 10px;
  height: 35px;
}
.tijiao {
  margin-top: 15px;
  background: #f3a539;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #f3a539;
}
.libao {
  background: #f4f4f4;
  border-radius: 4px;
  padding: 0 5px;
  padding-bottom: 20px;
}
.li_a {
  font-size: 14px;
  color: #000000;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
}
.redimg {
  width: 84px;
  //   height: 117px;
  display: block;
  margin: auto;
}
.zis {
  width: 104px;
  height: 38px;
  margin: auto;
  position: relative;
  margin-top: 15px;
  font-size: 16px;
}
.dingjw {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 7px;
  margin-left: 5px;
}
.zidingyi {
  width: 104px;
  height: 38px;
  margin: auto;
  border: 1px solid #f43f3f;
  border-radius: 2px;
  background: #ffffff;
  font-size: 12px;
  color: #f43f3f;
  line-height: 38px;
  text-align: center;
  margin-top: 15px;
}
.ziinput {
  width: 104px;
  height: 38px;
  padding-left: 20px;
  border: 1px solid #f43f3f;
  border-radius: 2px;
  background: #ffffff;
  color: #ff3535;
  line-height: 38px;
}
.hbdiv {
  overflow: hidden;
}
.hbdic {
  float: left;
  position: relative;
  width: 33%;
}
.hbdicd {
  position: relative;
  width: 33%;
  margin: auto;
}
.nube {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  margin-top: 60px;
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
}
.acddv {
  color: #999;
}
//
</style>