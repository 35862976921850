import axios from 'axios'
import { Toast } from 'vant'

// 创建axios实例
const service = axios.create({
//   baseURL: 'https://food.car-posthouse.cn/', 
  // baseURL: 'http://47.99.212.29:8888/', 
  baseURL: 'https://fooddev.car-posthouse.cn/api', 
  // 'http://47.96.248.209:8888/', // , // api的base_url
  timeout: 5000                  // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  const Authorization = localStorage.getItem("QTtoken")
  // const Authorization = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNDk1IiwiZXhwIjoxNjI0Njk2MjM2fQ.oP9WvuWvkGAIROk9DaE-0uMp0HrwWtLJvs4gFKLmQMtsglEVOu5j0kEX-VTSYdJtc0dj3I6Ai7a_bnZH_T9zgw'
  if (Authorization){
    config.headers['Authorization'] = Authorization
  }
  return config;
}, error => {
  Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 200) {
      return res
    } else {
      let err = response.message ? res.message : 'error'
      Toast(res.message)
      return Promise.reject('request faild')
    }
  },
  error => {
    if(error.response.status == 401){
      localStorage.removeItem('QTtoken')
      // window.location.href = '/user'
    }
    Toast(error.response.data.message)
    return Promise.reject(error.response.data)
  }
)

export default service
